@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap"); */

* {
  font-family: "Poppins", serif;
  font-weight: 400;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

.chevron-config {
  transition: all 500ms ease 0s;
  transform: translateY(2px);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.loader-spinner {
  animation: rotate 1.2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-fade {
  opacity: 0;
  animation-name: fade-in; /* nom de l'animation */
  animation-duration: 1s; /* durée de l'animation */
  animation-fill-mode: forwards; /* élément reste visible à la fin de l'animation */
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-fade-off {
  opacity: 0;
  animation-name: fade-in-reverse;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes fade-in-reverse {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-fade:nth-child(1) {
  animation-delay: calc(0.14s * 1);
}

.animate-fade:nth-child(2) {
  animation-delay: calc(0.14s * 2); /* 1s * position de l'élément (2ème) */
}

.animate-fade:nth-child(3) {
  animation-delay: calc(0.14s * 3); /* 1s * position de l'élément (3ème) */
}

.animate-fade:nth-child(4) {
  animation-delay: calc(0.14s * 4); /* 1s * position de l'élément (3ème) */
}

.animate-fade:nth-child(5) {
  animation-delay: calc(0.14s * 5); /* 1s * position de l'élément (3ème) */
}

.bg-transparent-black {
  background-color: #33333300;
  visibility: hidden;
  transition: background 220ms cubic-bezier(0.4, 0, 0.6, 1) 20ms,
    visibility 0.24s step-end;
}

.animation-fade-on {
  opacity: 1;
  visibility: visible;
  transform: translate(0);
  transition-delay: calc(0.2s + 5 * 20ms);
  transition-duration: 0.24s;
  transition-property: opacity, transform, visibility;
  transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1),
    cubic-bezier(0.4, 0, 0.6, 1), step-start;
  z-index: 99;
}

.animation-fade-off {
  opacity: 0;
  transform-origin: center;
  transform: translateX(-8px);
  transition-delay: calc(0.2s + 5 * 20ms);
  transition-duration: 0.24s;
  transition-property: opacity, transform, visibility;
  transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1),
    cubic-bezier(0.4, 0, 0.6, 1), step-start;
  z-index: -1;
}

@keyframes globalnav-chevron-hover-off {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}

/* 
* {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  color: black;
} */

.gradient-swiper {
  width: 100%;
  height: 100%;
  background: linear-gradient(74deg, #d82540, #0a4f87);
  background-size: 200% 100%;
  animation: gradientAnimation 5s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

input,
select,
textarea {
  outline: none;
}

.compare-plans tr th {
  width: 100px;
}

.compare-plans tr td {
  width: 100px;
}

.border-shadow-box {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.gradient-cloud {
  position: relative;
  overflow: hidden;
}

.text-ellipsis-3-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text-ellipsis-2-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.gradient-cloud::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background: linear-gradient(
    to bottom,
    rgba(238, 5, 5, 0.881),
    rgba(255, 0, 0, 0)
  );
  z-index: 1; /* Assurez-vous que le nuage est en arrière-plan */
}

.bg-shape {
  border-radius: 0% 100% 32% 68% / 28% 0% 100% 72%;
  background-color: #fef0e5;
}

/* .overlay::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  transition: all 0.4s ease-in-out;
  background: linear-gradient(
    to right,
    #00000070,
    transparent 450%,
    transparent
  );
} */

.bg-url {
  object-fit: cover;
  background: url("https://img.freepik.com/free-photo/person-near-alternative-energy-plant_23-2149192706.jpg?t=st=1713895086~exp=1713898686~hmac=125b6b0a1c0592d9a80c6e4eff3f157d59135623fabd8a88014d30ef8624beea&w=996");
  background-size: 100% 100%;
}

.bottom-gradient {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.357)
  );
}

.image-card:hover .social-card {
  display: flex;
  cursor: pointer;
  transition: visibility 0.3s;
}

.wavy-line {
  background-image: url("assets/images/line.png");
  background-size: 100% 100%;
}

.gradient-white-inversed {
  position: relative;
  display: inline-block;
  color: transparent;
  background: -webkit-linear-gradient(
    74deg,
    #000 0,
    #ff4460 24%,
    #3fa9ff 35%,
    #3fa9ff 44%,
    #3fa9ff 50%,
    #3fa9ff 100%
  );
  background: linear-gradient(
    74deg,
    #ff4460 0,
    #3fa9ff 24%,
    #3fa9ff 35%,
    #3fa9ff 44%,
    #3fa9ff 50%,
    #3fa9ff 100%
  );
  background-size: 400% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-white {
  position: relative;
  display: inline-block;
  color: transparent;
  background: -webkit-linear-gradient(
    74deg,
    #000 0,
    #3fa9ff 24%,
    #ff4460 35%,
    #ff4460 44%,
    #ff4460 50%,
    #ff4460 100%
  );
  background: linear-gradient(
    74deg,
    #3fa9ff 0,
    #ff4460 24%,
    #ff4460 35%,
    #ff4460 44%,
    #ff4460 50%,
    #ff4460 100%
  );
  background-size: 400% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient {
  position: relative;
  display: inline-block;
  color: transparent;
  background: -webkit-linear-gradient(
    74deg,
    #000 0,
    #1f69e0 24%,
    #de2537 35%,
    #de2537 44%,
    #de2537 50%,
    #de2537 100%
  );
  background: linear-gradient(
    74deg,
    #1f69e0 0,
    #de2537 24%,
    #de2537 35%,
    #de2537 44%,
    #de2537 50%,
    #de2537 100%
  );
  background-size: 400% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-profil:hover {
  width: 280px;
}

.card-profil:hover .card-profil-text {
  display: flex;
}

.card-profil:hover .card-profil-text-vert {
  display: none;
}

.card-profil:first-child {
  width: 280px;
}

.card-profil:not(:first-child):hover {
  width: 280px;
}

.card-profil:not(:hover) {
  width: 120px;
}

.animation-infinite {
  min-width: 100%;
  animation: scroll 10s linear 0s infinite normal;
  animation-play-state: running;
}

.text-wrap-balance {
  text-wrap: balance;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

p {
  font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
}

p {
  line-height: 1.4em;
}

@media (min-width: 320px) {
  p {
    line-height: calc(1.4em + 1vw - 3.2px);
  }
}

@media (min-width: 960px) {
  p {
    line-height: calc(1.4em + 6.4px);
  }
}

h1 {
  font-size: 2.55rem;
}

@media (min-width: 320px) {
  h1 {
    font-size: calc(2.55rem + 1.85vw - 10px);
  }
}

@media (min-width: 960px) {
  h1 {
    font-size: calc(2.55rem + 20px);
  }
}

@media (max-width: 768px) {
  .about-us {
    flex-direction: column;
  }

  .image-bg-about-us::after {
    content: "";
    position: absolute;
    bottom: -25px;
    left: 0;
    width: 100%;
    height: 45px;
    background-color: white;
    transform: skewY(-3deg);
    border-color: transparent;
    z-index: 1;
    border-color: #fff;
  }

  .card-profil {
    width: 100%;
  }

  .card-profil:first-child {
    width: 100%;
  }

  .card-profil:not(:hover) {
    width: 100%;
  }

  .card-profil .card-profil-text {
    display: flex;
  }

  .card-profil .card-profil-text-vert {
    display: none;
  }
}

.shadow-container::before,
.shadow-container::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 50px;
  pointer-events: none;
}

.shadow-container::after {
  bottom: -50px;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.645),
    rgb(255, 255, 255)
  );
}

.shadow-container::before {
  top: -50px;
  background: linear-gradient(
    to bottom,
    rgb(255, 255, 255),
    rgb(255, 255, 255)
  );
}

.card-profil-text-vert {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-180deg) scaleY(1); /* Rotation pour écrire verticalement */
  width: 200px; /* Ajustez la largeur selon vos besoins */
  white-space: nowrap; /* Empêche le texte de se couper */
  text-align: center; /* Centrage du texte */
}

.word {
  position: relative;
  display: inline-block;
  font-weight: 500;
}

.word-red::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3.7px;
  color: white;
  border-radius: 60% / 100%;
  background-color: #ff4460;
  transform: scaleX(0);
  transform-origin: left;
  animation: underline 1s cubic-bezier(0.22, 0.61, 0.36, 1) forwards;
  z-index: 100;
}

.word::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3.7px;
  border-radius: 60% / 100%;
  background-color: #0a4f87;
  transform: scaleX(0);
  transform-origin: left;
  animation: underline 1s cubic-bezier(0.22, 0.61, 0.36, 1) forwards;
  z-index: 100;
}

@keyframes underline {
  0% {
    transform: scaleX(0);
  }
  50% {
    transform: scaleX(1.2) scaleY(0.8);
    border-radius: 0 0 50% 50% / 0 0 100% 100%;
  }
  100% {
    transform: scaleX(1);
    border-radius: 50% 50% 0 0 / 100% 100% 0 0;
  }
}

.shape {
  background-size: 100% 100%;
  background-position: 0;
  background-repeat: no-repeat;
  background-image: url("https://i.pinimg.com/736x/9c/24/df/9c24df984057693d2f137546f3ee892a.jpg");
  border-radius: 41% 59% 70% 30% / 35% 30% 70% 65%;
}

.only-shape {
  transform: translate(0%, 0%);
  border-radius: 41% 59% 70% 30% / 35% 30% 70% 65%;
  animation: moveShape 1.5s ease forwards;
}

@keyframes moveShape {
  0% {
    transform: translate(0%, 0%);
  }
  100% {
    transform: translate(-15%, 10%);
  }
}

/* .card-profil:hover .card-profil:first-child {
  width: 120px;
} */

/* .card-profil:hover * {
  cursor: pointer;
}

.card-profil:hover {
  width: 280px;
}



 */

.border-shadow {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.shadow-box {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.shadow-box-md {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.header-bottom {
  background-image: linear-gradient(
    270deg,
    rgb(255, 255, 255),
    black 52.07%,
    rgb(255, 255, 255)
  );
}

.progress-bar-fill {
  width: 0%;
  transition: width 3s linear;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.animation {
  width: 0%;
  transition: width 3s linear;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

video {
  vertical-align: initial;
  overflow-clip-margin: content-box;
  overflow: clip;
}

.faq-transition {
  transition: 0.4s cubic-bezier(0.5, -0.27, 0.4, -0.17);
  /* transition: 0.4s ease-in-out; */
}

/* .fill-smooth {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
} */
